import { FC, useEffect, useMemo, useState } from 'react';
import { PlaceholderWordProps } from './PlaceholderWord';
import { HEADINGS, LISTS } from '../../../utils/MarkdownRenderers';
import MarkdownRenderers from '../../../utils/MarkdownRenderers';
import { ActionPlaceholderData } from '../../../models/Form';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import { actionDataInterpolationReplacer, interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';

const leafRenderer = MarkdownRenderers({});

export const AnswerPlaceholderWordPlugin = (placeholderData: Record<string, ActionPlaceholderData>): FC<PlaceholderWordProps> => {
  return function AnswerPlaceholderWord(props) {
    const { id, children, styles, textboxDisabled } = props;
    const { t } = useTranslation('form');
    const isTag = useMemo(() => ('isTag' in props ? props.isTag : undefined), [props]);
    const [fetchTimeoutReached, setFetchTimeoutReached] = useState(false);

    useEffect(() => {
      if (fetchTimeoutReached) return;

      const timeout = setTimeout(() => {
        setFetchTimeoutReached(true);
      }, 3000);

      return () => clearTimeout(timeout);
    }, [fetchTimeoutReached]);

    if (!isTag) {
      let word = (
        <span className={`${textboxDisabled ? '' : 'text-black'} cursor-text leading-[25px] outline-none`} data-placeholder-role="word">
          {children}
        </span>
      );

      for (const style of styles || []) {
        if (HEADINGS.includes(style)) {
          word = leafRenderer[style]({ children: word, level: style.replace('h', '') });
        } else if (LISTS.includes(style)) {
          word = leafRenderer[style]({ children: leafRenderer['li']({ children: word }) });
        } else {
          word = leafRenderer[style]({ children: word });
        }
      }

      return word;
    }

    return (
      <Tooltip text={t('dynamic-data-in-answer')}>
        {(tooltip) => (
          <span
            {...tooltip}
            className={`bg-gray-4 text-dpm-14 cursor-default select-none rounded-lg px-2 py-1 align-middle font-normal leading-[25px] text-black opacity-100 outline-none`}
            contentEditable={false}
            data-placeholder-role="tag"
            data-tag-id={id}
            key={fetchTimeoutReached ? 'timeout' : 'loading'}
          >
            {interpolateActionData(id, placeholderData, interpolator(fetchTimeoutReached))}
            {children}
          </span>
        )}
      </Tooltip>
    );
  };
};

const interpolator = (timeoutReached: boolean) =>
  function AnswerInterpolator(
    placeholder: string,
    placeholderData: ActionPlaceholderData,
    key: number,
    otherPlaceholders: Record<string, ActionPlaceholderData>,
  ) {
    if (!timeoutReached && !placeholderData) {
      return <Loader key={key} centered={false} size={4} style={{ display: 'inline-block', marginBottom: '-0.25rem' }} />;
    }

    return actionDataInterpolationReplacer(placeholder, placeholderData && { ...placeholderData, dataFormat: 'comma' }, key, otherPlaceholders);
  };
